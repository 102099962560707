<template>
  <section class="sign-in-page scroll-auth">
    <div id="circle-small"></div>
    <div id="circle-medium"></div>
    <div id="circle-large"></div>
    <div id="circle-xlarge"></div>
    <div class="d-xl-block d-none" id="circle-xxlarge"></div>
    <v-container class="p-0">
      <v-row class="no-gutters">
        <v-col md="6" class="text-center d-md-block d-none">
          <div class="sign-in-detail text-white">
            <router-link :to="{ name: 'social.list' }" class="sign-in-logo">
              <img
                src="../../assets/images/logo/logo-prosk-1.svg"
                class="img-fluid"
                alt="logo"
              />
            </router-link>
          </div>
        </v-col>
        <div class="text-center w-100 mt-5 d-block d-md-none">
          <a class="sign-in-logo mb-5" href="#">
            <img
              src="../../assets/images/logo/logo-prosk-1.svg"
              class="img-fluid"
              alt="logo"
            />
          </a>
        </div>
        <v-col md="6" cols="12" class="bg-white my-md-12 shadow-lg pt-md-5">
          <div class="sign-in-from">
            <router-view></router-view>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
import { socialvue } from '@/config/pluginInit';

export default {
  name: 'AuthLayout1',
  mounted () {
    socialvue.index();
  },
  data () {
    return {};
  }
};
</script>
